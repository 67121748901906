body {
  --main-bg-color: #ECECEA;
  --accent-color: #FFBF5D;
  --main-color: #009B7D;

  --logo-width: 270px;
  --logo-height: 110px;

  --main-font: 'Archivo';

  --fields-border: 5px;
  --fields-border-color: #D69723;
  --labels-margin-bottom: 1rem;
  --inputs-margin-bottom: 0.4rem;
  --inputs-font-weight: bold;

  margin: 0;
  font-family: -apple-system, var(--main-font),
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-bg-color);
  color: var(--main-color);

}

code {
  font-family: Monaco, Consolas, 'Roboto',
    monospace;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

h1{
  font-size: 2rem;
}
